import { Button } from "@storybook";
import { OfflineSvg } from "./image/offline";

import "./offline.scss";
import { useCallback } from "react";

export const Offline = () => {
  const onClickHandler = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <div className="Offline__container">
      <div
        className="Offline__image"
        dangerouslySetInnerHTML={{
          __html: OfflineSvg,
        }}
      />

      <p className="Offline__text">You’re offline</p>
      <p className="Offline__desc">
        Your internet connection in unavailable. Please check your data or wifi
        connection.
      </p>
      <Button
        type="Offline__container--btn"
        label="Retry"
        handleClick={onClickHandler}
        backgroundColor="#2251d3"
      />
    </div>
  );
};

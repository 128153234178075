import { FC, useMemo } from "react";
import { Button, Loader } from "@storybook";

import image from "assets/svg/approve.svg";
import AppleLogin from "react-apple-login";
import { PREVENT_APPLE_LOGIN, approveTexts } from "./constant";
import { isMacOrIOS } from "utils";

interface IProps {
  handleSelectOption: (e: "accept" | "reject", cb: any) => void;
  code: string;
  hostType?: string;
  isLoading: boolean;
}

export type IOption = "accept" | "reject";

interface IButtonsRow {
  label: string;
  option: IOption;
}

const buttonsRow: IButtonsRow[] = [
  {
    label: "Approve",
    option: "accept",
  },
  {
    label: "Deny",
    option: "reject",
  },
];

export const MobileApprove: FC<IProps> = ({ handleSelectOption, code, hostType, isLoading }) => {
  const renderButtons = useMemo(() => {
    return isMacOrIOS && !PREVENT_APPLE_LOGIN.includes(window.location.host) && hostType && hostType!=='super' ? (
      <AppleLogin
        clientId="com.simplici.service.login"
        redirectURI={window.location.origin}
        usePopup={true}
        callback={() => ({})} // Catch the response
        scope="email name"
        responseMode="query"
        render={(
          renderProps //Custom Apple Sign in Button
        ) => (
          <>
            {buttonsRow.map(({ label, option }) => (
              <Button
                key={option}
                handleClick={() =>
                  handleSelectOption(option, renderProps.onClick)
                }
                label={isLoading && option === 'accept' ? <Loader dimension={20} /> : label}
                disabled={isLoading}
                type="button__filled button__filled--secondary button__large button__block MobileApprove__btn"
              />
            ))}
          </>
        )}
      />
    ) : (
      <>
        {buttonsRow.map(({ label, option }) => (
          <Button
            key={option}
            handleClick={() => handleSelectOption(option, () => ({}))}
            label={isLoading && option === 'accept' ? <Loader dimension={20} /> : label}
            disabled={isLoading}
            type="button__filled button__filled--secondary button__large button__block MobileApprove__btn"
          />
        ))}
      </>
    );
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <div className="MobileApprove">
      <div className="MobileApprove__row">
        <div className="MobileApprove__title">
          {approveTexts[code]?.heading}
        </div>
      </div>
      <div className="MobileApprove__row">
        <div className="MobileApprove__logo">
          <img className="MobileApprove__logo" src={image} alt="logo" />
        </div>
        <div className="MobileApprove__description">
          {approveTexts[code]?.description}
        </div>
      </div>
      <div className="MobileApprove__row MobileApprove__btn-row">
        {renderButtons}
      </div>
    </div>
  );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import {
  MobileVerification,
  NotFoundError,
  Offline,
  useCheckOffline,
} from "views";
import { API_URL } from "constant";
import { REACT_APP_API_HOST_GLOBAL } from "./envs";
import { Loader } from "@storybook/loader/loader";
import { useLocation } from "hooks";
import "./main.scss";

export const Main = () => {
  const [isUrlLoader, setIsLoader] = useState(true);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const { isOnline } = useCheckOffline();
  const { fetchLocation } = useLocation();
  const browserPathName = window.location.pathname;

  const getOriginalUrlApi = useCallback(() => {
    fetch(`${REACT_APP_API_HOST_GLOBAL}${API_URL.SHORT_URL}${browserPathName}`)
      .then((res) => {
        if (res.status === 400) {
          setIsInvalidUrl(true);
        }
        return res.json();
      })
      .then((response) => {
        if (
          response?.data?.loginCode ||
          response?.data?.verificationCode ||
          response?.data?.biometricCode
        ) {
          const {
            countryCode,
            loginCode,
            phone,
            verificationCode,
            biometricCode,
          } = response.data;
          window.location.href = `${window.location.origin}/?verificationCode=${
            loginCode || verificationCode || biometricCode
          }&phone=${phone}&countryCode=${countryCode}`;
        }
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, [browserPathName]);

  useEffect(() => {
    if (
      !/verificationCode/.test(window.location.href) &&
      browserPathName.length > 2
    ) {
      getOriginalUrlApi();
    } else {
      setIsLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Init fullstory
    if (!browserPathName) return;
    (window as any).FS?.identify?.(browserPathName);
  }, [browserPathName]);

  const renderPage = useMemo(() => {
    if (!isOnline) return <Offline />;
    else return <MobileVerification />;
  }, [isOnline]);

  if (isUrlLoader) {
    return (
      <div className="mobile-verification">
        <Loader />
      </div>
    );
  }

  if (isInvalidUrl) {
    return <NotFoundError />;
  }

  return <>{renderPage}</>;
};

export const PREVENT_APPLE_LOGIN = [
  "admin.stage.satschel.com",
  "admin.pp.satschel.com",
];

interface IApproveText {
  [key: string]: {
    heading: string;
    description: string;
  };
}

export const approveTexts: IApproveText = {
  biometricCode: {
    heading: "Is it you trying to register biometric?",
    description: "Click approve if it is you trying to register your biometric",
  },
  loginCode: {
    heading: "Is it you trying to login?",
    description: "Click approve if it is you trying to sign-in to your account",
  },
  verificationCode: {
    heading: "Verify your mobile number",
    description: "Click approve if it is you trying to verify to your account",
  },
  inviteUserCode: {
    heading: "Verify your mobile number",
    description: "Click approve to accept the invitation",
  },
};

export const LOGO = {
  LIGHT:
    "https://storage.googleapis.com/satschel-public-assets/images/logo/simplici-wordmark.png",
};

export const IMAGE_GCP_URL =
  "https://storage.googleapis.com/satschel-assets-public/assets/illustrations/simplici-media";

export const GOOGLE_API = {
  IP: "https://us-central1-glossy-fastness-305315.cloudfunctions.net/locate",
};

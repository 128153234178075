import { GOOGLE_API } from "constant";
import { useLocation } from "hooks";

export const useGetLoationDetails = () => {
  const { getLocation } = useLocation();

  const getGeoInfo = async () => {
    const location = getLocation();
    const { longitude = "", latitude = "" }: any = location ?? {};

    try {
      const paylaod = {
        location: { lat: latitude, lng: longitude },
        ip: true,
      };

      const response = await fetch(GOOGLE_API.IP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paylaod),
      });
      if (response.ok) {
        const apiData = await response.json();
        return apiData;
      }
    } catch (err) {
      return {};
    }
  };

  return {
    getGeoInfo,
  };
};

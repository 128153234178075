import { useCallback } from "react";

export const useLocation = () => {
  const fetchLocation = useCallback(
    async () =>
      await navigator.geolocation.getCurrentPosition(
        (info) => {
          if (info?.coords) {
            const location = {
              accuracy: info.coords.accuracy,
              altitude: info.coords.altitude,
              altitudeAccuracy: info.coords.altitudeAccuracy,
              latitude: info.coords.latitude,
              longitude: info.coords.longitude,
            };
            localStorage.setItem("userLocation", JSON.stringify(location));
            return location;
          }
          return {};
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => ({}),
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getLocation = useCallback(() => {
    const storedLocation = localStorage.getItem("userLocation");
    if (storedLocation) {
      return JSON.parse(storedLocation);
    }
    return null;
  }, []);

  return { fetchLocation, getLocation };
};

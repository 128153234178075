import { ErrorIcons } from "components/status";

export const Error = () => {
  return (
    <div className="mobile-verification__error">
      <div className="mobile-verification__svg-height">
        <ErrorIcons />
      </div>
      <div className="mobile-verification__success-message">
        Approval denied or link expired
      </div>
    </div>
  );
};

import SuccessIcon from "assets/gif/success.gif";

export const Success = () => {
  return (
    <div className="mobile-verification__success">
      <img src={SuccessIcon} alt="" />
      <div className="mobile-verification__success-message">
        Approved successfully
      </div>
    </div>
  );
};

import { IMAGE_GCP_URL } from "constant";
import "./not-found.scss";

export const NotFoundError = () => {
  return (
    <div className="NotFoundError__container">
      <div className="NotFoundError__container--image">
        <img src={`${IMAGE_GCP_URL}/missing.svg`} alt="Missing" />
        <h4 className="NotFoundError__container--title">
          The requested URL was not found on this server.
        </h4>
      </div>
    </div>
  );
};

import { useCallback } from "react";
import { toast } from "react-toastify";

export const useNotification = () => {
  const successNotification = useCallback((msg: any) => {
    toast.success(msg);
  }, []);

  const infoNotification = useCallback((msg: any) => {
    toast.info(msg);
  }, []);

  const errorNotification = useCallback((msg: any) => {
    toast.error(msg);
  }, []);

  const warningNotification = useCallback((msg: any) => {
    toast.warning(msg);
  }, []);

  return {
    successNotification,
    infoNotification,
    errorNotification,
    warningNotification,
  };
};

import { Button } from "@storybook";
import CloseIcon from "assets/svg/closeIcon.svg";
import "./invalid-code.scss";

export const InvalidCode = () => {
  const onNavigateLogin = () => {
    window.location.reload();
  };

  return (
    <div className="InvalidCode--conatiner">
      <img src={CloseIcon} alt="Close-Icon" />
      <div className="MobileApprove__description">Invaild Code</div>
      <Button
        type="InvalidCode--conatiner__btn"
        label="Close"
        handleClick={onNavigateLogin}
        buttonType="button"
      />
    </div>
  );
};

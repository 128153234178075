import { IButton } from ".";

import { FC } from "react";

import "./button.scss";

export const Button: FC<IButton> = ({
  label,
  handleClick,
  loader,
  type,
  icon,
  height,
  disabled,
  buttonType,
  prefixIcon,
  minHeight,
  width,
  backgroundColor = "#ebeef5",
}) => {
  return (
    <button
      className={`button ${type} ${disabled ? "button--disabled" : ""}`}
      onClick={handleClick}
      style={{ minHeight, height, width, backgroundColor }}
      disabled={disabled}
      type={buttonType}
    >
      {prefixIcon && <i className={prefixIcon} />}
      <div className="button-label-txt">{label}</div>
      {loader}
      {icon && <i className={icon} />}
    </button>
  );
};
